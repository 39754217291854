<template>
    <section>
      <div class="w-full bg-white p-4">
        <div class="flex w-full py-4 mt-4 items-center border-b">
          <i class="pi pi-cog mr-4 text-blue-800"></i>
          <p class="text-xl text-blue-800 font-bold">Configuración</p>
        </div>
        <router-view></router-view>
      </div>
    </section>
  </template>
  <script>
    import { onMounted } from 'vue'
    export default {
    name: 'ConfiguracionLogisticaPharmasan',
    setup () {
      onMounted(() => {
      })
      return {}
    }
  }
  </script>

  <style scoped>
    /* ::v-deep(.p-tieredmenu .p-menuitem) {
      background-color: red;
    } */
    ::v-deep(.p-tieredmenu .p-menuitem a) {
      background-color: green !important;
    }
    .on {
      border-bottom: 3px solid #1C3FAA;
      width: 12%;
      text-align: center;
    }
    .off {
      width: 12%;
      text-align: center;

    }
    ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  </style>
